import React from "react"
import { Nav, NavItem } from 'reactstrap';
import { NavLink } from 'react-router-dom';
// import Dashboard from "../../assets/icons/dashboard.svg"
import Status from "../../assets/icons/status.svg"
import Configuration from "../../assets/icons/configuration.svg"
import { useLocation } from "react-router-dom";

const LeftSidebar = (props) => {
  const pathname = useLocation().pathname
  return (
    <>
      <div className="left-navbar__width sidebar">
        <Nav vertical className="">
          <NavItem>
            <NavLink to="/users" className={pathname === "/users" ? "active" : ""}>
              <img src={Status} className="nav-icon" alt="" />
              Users
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink to="/transactions" className={pathname === "/transactions" ? "active" : ""}>
              <img src={Configuration} className="nav-icon" alt="" />
              Transactions
            </NavLink>
            <NavLink to="/licensing" className={pathname === "/licensing" ? "active" : ""}>
              <img src={Configuration} className="nav-icon" alt="" />
              Licensing
            </NavLink>
            <NavLink to="/settings" className={pathname === "/settings" ? "active" : ""}>
              <img src={Configuration} className="nav-icon" alt="" />
              Settings
            </NavLink>
          </NavItem>
        </Nav>
      </div>
    </>
  )

}
export default LeftSidebar
