import React from "react"
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import AddBalanceFrom from "./components/addBalanceFrom"

const AddBalanceForm = (props) => {
  const {
    className,
    modal,
    setModal,
    dataForAddBal
  } = props;

  const name = dataForAddBal ? dataForAddBal.name : ""
  // console.log("AddBalanceForm ..... ", dataForAddBal)

  return (
    <>
      <Modal isOpen={modal} centered={true} zIndex={999999999} className={className} tabIndex="4" role="dialog">
        <ModalHeader tag="h6" className="py-2 text-dark bg-light shadow-sm">Add Balance for {name}</ModalHeader>
        <ModalBody>

          <AddBalanceFrom setModal={setModal} dataForAddBal={dataForAddBal} />

        </ModalBody>

      </Modal>
    </>
  )
}

export default AddBalanceForm
