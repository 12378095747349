import { gql } from "apollo-boost"


export const UPDATEADMINSETTING = gql`
mutation updateAdminSetting($input : JSON!){
  updateAdminSetting(input :$input )
}
`
export const GETADMINSETTING = gql`
query getAdminSetting
{ 
  getAdminSetting{
    id
    montly_license_price
  }
}
` 