import moment from "moment"
export const columnDefs = [
    // { headerName: "Id", field: "id", sortable: true, filter: "agTextColumnFilter", width: 90, editable: false },
    { headerName: "Mac Aaddress", editable: false, field: "mac_address", sortable: true, filter: "agTextColumnFilter" },
    {
        headerName: "Expiration", field: "expiration", sortable: true, filter: "agTextColumnFilter",
        cellEditor: "datePickerRender",
        cellRenderer: (params) => {
            console.log("params--------------> ", params.value)
            return params.value
        },
    },
    { headerName: "Owner ", field: "name", sortable: true, filter: "agTextColumnFilter", editable: false },
    {
        headerName: "Days Left", field: "expiration", sortable: true, filter: "agTextColumnFilter", editable: false,
        cellRenderer: (params) => {
            const itemTime = moment(params.value)
            const curentTime = moment(new Date())
            const diff = itemTime.diff(curentTime)
            const d = moment.duration(diff);
            const days = parseInt(d.asDays()); //84 
            // console.log(d.asDays(),d.asHours())
            let hours = parseInt(d.asHours()); //2039 hours, but it gives total hours in given miliseconds which is not expacted.
            hours = hours - days * 24;  // 23 hours
            return `${days}d ${hours}h`

            // const itemTime = moment(params.value)
            // const curentTime = moment(new Date())
            // const diff = itemTime.diff(curentTime, 'days')
            // return diff
        }
    },
]