import React from "react";
import { Col, Button, Form, Label, Input } from "reactstrap";
import { useFormik } from "formik";
// import { useMutation } from "@apollo/react-hooks"
// import { ADD_SCRIPT_SETTING, UPDATE_SCRIPT_SETTING } from "../SettingsGraphQL"
import { isEmpty } from "lodash";
import * as Yup from "yup";

const MonthlyLicensePrice = props => {
  // const customerId = parseInt(localStorage.getItem('consolebot.userId'))
  // const [addScript] = useMutation(ADD_SCRIPT_SETTING);
  // const [updateScript] = useMutation(UPDATE_SCRIPT_SETTING)
  const { setting, toastMessgage, updateAdminSetting } = props;
  const formik = useFormik({
    initialValues: {
      montly_license_price: setting ? setting.montly_license_price : 0
    },
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      montly_license_price: Yup.string().required("Required")
    }),
    onSubmit: async values => {
      const data = { ...values };
      data.id = setting ? parseInt(setting.id) : 0;
      // data.customer_id = customerId
      try {
        const variables = { input: data };
        await updateAdminSetting({ variables });
        toastMessgage("success", "Update script setting!");
        // isSubmit = false
      } catch (e) {
        toastMessgage("error", e.message);
        // isSubmit = false
      }
    }
  });

  const { handleChange, handleSubmit, dirty, errors, isValid } = formik;

  const { montly_license_price } = formik.values;

  const isValidField = fieldName => {
    if (!isEmpty(errors) && errors[fieldName]) {
      return true;
    }
    return false;
  };
  return (
    <div>
      <Form onSubmit={handleSubmit}>
        <div className="mb-4">
          <Col sm={6}>
            <div className="single-line">
              <Label for="montly_license_price">Monthly License Price</Label>
              <Input
                type="number"
                name="montly_license_price"
                id="montly_license_price"
                placeholder="montly_license_price"
                onChange={handleChange}
                value={montly_license_price}
                invalid={isValidField("montly_license_price")}
              />
              <Button
                color="primary"
                type="submit"
                disabled={!dirty || !isValid}
              >
                Save
              </Button>
            </div>
          </Col>
        </div>
      </Form>
    </div>
  );
};

export default MonthlyLicensePrice;
