import React from "react"
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import AddUserFrom from "./components/addUserFrom"

const AddUser = (props) => {
  const {
    className,
    modal,
    setModal,
    refetch
  } = props;

  return (
    <>
      <Modal isOpen={modal} centered={true} zIndex={999999999} className={className} tabIndex="4" role="dialog">
        <ModalHeader tag="h6" className="py-2 text-dark bg-light shadow-sm">Add new user</ModalHeader>
        <ModalBody>

          <AddUserFrom setModal={setModal} refetch={refetch}/>

        </ModalBody>

      </Modal>
    </>
  )
}

export default AddUser
