import { gql } from "apollo-boost";

export const UPDATECUSTOMER = gql`
  mutation updateCustomer($data: JSON!) {
    updateCustomer(input: $data)
  }
`;
export const DELETECUSTOMER = gql`
  mutation deleteCustomer($id: [Int]!) {
    deleteCustomer(id: $id)
  }
`;
export const ADDCUSTOMER = gql`
  mutation addCustomer($customerDetails: JSON!) {
    addCustomer(input: $customerDetails) {
      id
      name
      email
      password
      is_super_admin
      is_desktop
      balance
      skype
      telegram
      notes
      ea_reg
      psn_reg
      psn_signin
    }
  }
`;
export const GETCUSTOMER = gql`
  query getCustomer {
    getCustomer {
      id
      name
      email
      password
      is_super_admin
      is_desktop
      balance
      skype
      telegram
      notes
      ea_reg
      psn_reg
      psn_signin
    }
  }
`;

export const ISEMAILEXSIT = gql`
  mutation isEmailExsit($email: String!) {
    isEmailExsit(email: $email) {
      id
      email
      name
      password
      is_super_admin
      balance
    }
  }
`;

export const USERINFO_SUBSCRIBE = gql`
  subscription userInfo($userId: Int!) {
    userInfo(userId: $userId)
  }
`;

export const BALANCE_UPDATE_ADMIN = gql`
  subscription balanceUpdateAdmin {
    balanceUpdateAdmin
  }
`;
