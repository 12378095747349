import React, { useState } from "react"
import {
  Row,
  Col,
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from 'reactstrap'; 
import Logo from "./../../assets/images/logo.png"
import ProfileImg from "./../../assets/images/user-profile-img.jpg"
const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  return (
    <>
      <header className="app-header fixed-top shadow-sm bg-white">
        <Navbar expand="md" className="py-1">
          <NavbarBrand href="/"><img src={Logo} alt="ConsoleBot" /></NavbarBrand>
          <NavbarToggler onClick={toggle} />
          <div className="ml-auto">
            <Collapse isOpen={isOpen} navbar>
              <Row>
                <Col xs="auto">
                  <Nav className="d-inline-flex align-items-center"> 
                    <UncontrolledDropdown >
                      <DropdownToggle nav className="px-0">
                        <img src={ProfileImg} className="rounded-circle" width="36" alt="user" />
                      </DropdownToggle>
                      <DropdownMenu right>
                        <DropdownItem>
                          Profile
                        </DropdownItem>
                        <DropdownItem href="login">
                          Logout
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </Nav>
                </Col>
              </Row>
            </Collapse>
          </div>
        </Navbar>
      </header>
    </>
  )
}

export default Header
