import React, { PureComponent } from "react"
import { Switch, Route } from "react-router-dom"
// import Dashboard from "../pages/Dashboard" 
import Users from "../pages/Users" 
import Header from "../common/Header"
import LeftSidebar from "../common/LeftSidebar"
import Transactions from "../pages/Transactions";
import Licensing from "../pages/Licensing";
import Settings from "../pages/Settings"

class AppContainer extends PureComponent {
  render() {
    return (
      <section>
        <Header />
        <section className="app-wrapper">
          <LeftSidebar /> 
          <Switch>
            <Route exact path="/" component={Users} />
            {/* <Route path="/dashboard" component={Dashboard} /> */}
            <Route path="/users" component={Users} />
            <Route path="/transactions" component={Transactions} />
            <Route path="/licensing" component={Licensing} /> 
            <Route path="/settings" component={Settings} />                        
          </Switch>
        </section>
      </section>
    )
  }
}
export default AppContainer
