import React, { Component } from 'react';
import { Input } from 'reactstrap';

export default class DatePickerRender extends Component {

    state = { date: this.props.value } 

    getValue() {
        return this.state.date  
    } 
    dateChange = (event) =>{
        this.setState({date: event.target.value})
        // console.log("dateChange  ", event.target.value)
    }
    render() { 
        return (
            <div className="date-picker">
                <Input type="date" onChange={this.dateChange} value={this.state.date} /> 
            </div>
        );
    }
}