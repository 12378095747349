import { gql } from "apollo-boost"


export const UPDATELICENSE = gql`
mutation updateLicense($data : JSON!){
  updateLicense(input :$data )
}
`
export const DELETELICENSE = gql`
mutation deleteLicense($id : [Int]!){
  deleteLicense(id :$id )
}
`
export const ADDLICENSE = gql`
mutation addLicense($licensDetails : JSON!){
  addLicense(input :$licensDetails ){
    id	 
  }
}
`
export const GETLICENSE = gql`
query getLicense
{ 
  getLicense{
    id
    mac_address
    expiration
    customer_id
    name
    email
    password
    is_super_admin
    is_desktop
    balance
    skype
    telegram
    notes
  }
}
` 