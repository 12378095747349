import React from "react"
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import AddTransactionFrom from "./components/addTransactionFrom"

const AddTransaction = (props) => {
  const {
    className,
    modal,
    setModal,
  } = props;

  return (
    <>
      <Modal isOpen={modal} centered={true} zIndex={999999999} className={className} tabIndex="4" role="dialog">
        <ModalHeader tag="h6" className="py-2 text-dark bg-light shadow-sm">Add new transaction</ModalHeader>
        <ModalBody>

          <AddTransactionFrom setModal={setModal} />

        </ModalBody>

      </Modal>
    </>
  )
}

export default AddTransaction
