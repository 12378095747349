export const columnDefs = [
    // { headerName: "Id", field: "id", sortable: true, filter: "agTextColumnFilter", width: 90, editable: false },
    { headerName: "Date", field: "t_date", sortable: true, filter: "agTextColumnFilter", width: 90 },
    { headerName: "Type", field: "type", sortable: true, filter: "agTextColumnFilter", width: 90 },
    { headerName: "Issuer", field: "issuer", sortable: true, filter: "agTextColumnFilter" , width: 90},
    {
        headerName: "User Email", field: "useremail", sortable: true, filter: "agTextColumnFilter"
    },
    { headerName: "Amount (€)", field: "amount", sortable: true, filter: "agTextColumnFilter", width: 90 },
    { headerName: "Starting Balance", field: "starting_balance", sortable: true, filter: "agTextColumnFilter", width: 90 },
    { headerName: "Balance After", field: "balance_after", sortable: true, filter: "agTextColumnFilter", width: 90 },
    { headerName: "Note", field: "note", sortable: true, filter: "agTextColumnFilter" }   
]   