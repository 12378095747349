import React from "react";
import { Col, Row, Button, Form, Label, Input, FormGroup } from "reactstrap";
import { Formik } from "formik";
import * as Yup from "yup";
// import generator from "generate-password"
import { useToasts } from "react-toast-notifications";
import { useMutation } from "@apollo/react-hooks";
import { ADDTRANSACTION } from "../../../../Transactions/TransactionGraphQL";
// import { IoIosEye, IoIosEyeOff } from "react-icons/io";

const initialValues = {
  type: "Topup",
  note: "",
  amount: 0
};

const validation = Yup.object().shape({
  note: Yup.string().required("Required"),
  amount: Yup.string().required("Required")
});

const AddTransactionFrom = props => {
  const { addToast } = useToasts();
  const { setModal } = props;
  const [addTransaction] = useMutation(ADDTRANSACTION);

  const toastMessgage = (type, msg) => {
    addToast(msg, {
      appearance: type,
      autoDismiss: true
    });
  };

  const submitForm = async values => {
    const { dataForAddBal } = props;
    const email = localStorage.getItem("consolebot.email");
    const newAmt =
      values.amount === null ||
      values.amount === undefined ||
      values.amount === ""
        ? parseFloat(dataForAddBal.balance)
        : parseFloat(values.amount) + parseFloat(dataForAddBal.balance);

    const data = {
      t_date: new Date(),
      type: values.type,
      issuer: email,
      useremail: dataForAddBal.email,
      amount: values.amount,
      starting_balance: dataForAddBal.balance,
      balance_after: newAmt,
      note: values.note,
      customer: dataForAddBal
    };
    try {
      await addTransaction({ variables: { transDetails: data } });
      toastMessgage("success", "Done");
      setModal(false);
    } catch (e) {
      toastMessgage("error", e.message);
    }
  };

  return (
    <div>
      <Formik
        initialValues={initialValues}
        onSubmit={async values => {
          submitForm(values);
        }}
        validationSchema={validation}
      >
        {props => {
          const {
            values,
            touched,
            errors,
            handleChange,
            handleBlur,
            handleSubmit,
            isValid
          } = props;
          return (
            <Form onSubmit={handleSubmit}>
              <FormGroup>
                <Label for="Type">Select Type</Label>
                <Input type="select" name="Type" id="Type">
                  <option selected value="Topup">
                    Topup
                  </option>
                  <option value="License">License</option>
                </Input>
              </FormGroup>

              <FormGroup>
                <Label for="amount">Amount</Label>
                <div style={{ display: "flex", alignItems: "baseline" }}>
                  <Input
                    id="amount"
                    placeholder="Enter start balance"
                    type="number"
                    value={values.amount}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className={
                      errors.amount && touched.amount
                        ? "text-input error"
                        : "text-input"
                    }
                  />
                  <span style={{ fontSize: "26px", fontWeight: 500 }}>€</span>
                </div>
                {errors.amount && touched.amount && (
                  <div className="input-feedback">{errors.amount}</div>
                )}
              </FormGroup>

              <FormGroup>
                <Label for="note">Note</Label>
                <Input
                  id="note"
                  placeholder="Enter note"
                  type="text"
                  value={values.note}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={
                    errors.note && touched.note
                      ? "text-input error"
                      : "text-input"
                  }
                />
                {errors.note && touched.note && (
                  <div className="input-feedback">{errors.note}</div>
                )}
              </FormGroup>

              <Row form>
                <Col md={6}></Col>
                <Col md={6} style={{ textAlign: "right" }}>
                  <Button
                    color="primary"
                    amount="sm"
                    type="submit"
                    disabled={!isValid}
                  >
                    Submit
                  </Button>{" "}
                  <Button
                    color="primary"
                    amount="sm"
                    type="buttom"
                    onClick={() => setModal(false)}
                  >
                    Cancel
                  </Button>
                </Col>
              </Row>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default AddTransactionFrom;
