import React, { useState } from "react"
import { Input } from 'reactstrap';

export const CheckBoxRender = (props) => {
    const [is_admin, setAdmin] = useState(props.data.is_super_admin)
    console.log(props.data)
    const handleChange = (event) => {
        console.log(props.data)
        setAdmin(event.target.checked)
    }

    return (
        <>
            <div className='custom-control custom-switch'>
                <Input
                    type='checkbox'
                    className='custom-control-input'
                    id='is_super_admin'
                    checked={is_admin}
                    onChange={handleChange}
                    readOnly
                />
                <label className='custom-control-label' htmlFor='is_super_admin'> </label>
            </div>
            {/* <Input type="checkbox" checked={props.data.is_super_admin} />{' '} */}
        </>
    )
}
export default CheckBoxRender