import { HttpLink, InMemoryCache, ApolloClient } from "apollo-client-preset";
import { ApolloLink, split } from "apollo-link";
import { createUploadLink } from "apollo-upload-client";
import { WebSocketLink } from "apollo-link-ws";
import { getMainDefinition } from "apollo-utilities";
import usersReducer from "../pages/Users/UserReducers";
// import  settingsReducer from "../pages/Transactions/SettingsReducers";
import { createStore, combineReducers, compose, applyMiddleware } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import reduxStorage from "./storage";
import thunk from "redux-thunk";
import { onError } from "apollo-link-error";

// let uri = "http://consolebot-api.47billion.com/graphql"
// let uri = "http://localhost:4000/graphql"
// let wsUrl = 'ws://localhost:4000/graphql'
let uri = `https://${process.env.REACT_APP_GRAPHQL_URI}`;
let wsUrl = `wss://${process.env.REACT_APP_GRAPHQL_URI}`;

if (process.env.NODE_ENV === "production") {
  uri = `https://${process.env.REACT_APP_GRAPHQL_URI}`;
  wsUrl = `wss://${process.env.REACT_APP_GRAPHQL_URI}`;
  // uri = "http://api.console-bot.com/graphql";
  // wsUrl = "ws://api.console-bot.com/graphql";
  // uri = "http://consolebot-api.47billion.com/graphql"
  // wsUrl = 'ws://consolebot-api.47billion.com/graphql'
}

// uri = "http://api.console-bot.com/graphql"
//   wsUrl = 'ws://api.console-bot.com/graphql'
// wsUrl = 'ws://api.console-bot.com/graphql'

const wsLink = new WebSocketLink({
  uri: wsUrl,
  options: {
    reconnect: true
  }
});

const uploadLink = createUploadLink({ uri });
const httpLink = new HttpLink({ uri });
const cache = new InMemoryCache();

const authMiddleware = new ApolloLink((operation, forward) => {
  // add the authorization to the headers
  const token = localStorage.getItem("consolebot.token") || "";
  const userid = localStorage.getItem("consolebot.userId") || "";
  const email = localStorage.getItem("consolebot.email") || "";
  // const key = localStorage.getItem('consolebot.key') || ''
  if (!token && !window.location.href.includes("login")) {
    logoutUser();
  }
  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      "Access-Control-Allow-Origin": "*",
      authorization: token,
      userid,
      email
      // key
    }
  }));

  return forward(operation);
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  console.log("graphQLErrors  ", graphQLErrors);
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) => {
      if (message.includes("Auth token is expired")) {
        logoutUser();
      }
    });
  // if (networkError) console.log(`[Network error]: ${networkError}`);
});

// apollo client setup
// using the ability to split links, you can send data to each link
// depending on what kind of operation is being sent
const link = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    // console.log("Definition ---------", definition.operation);
    return (
      definition.kind === "OperationDefinition" &&
      definition.operation === "subscription"
    );
  },
  wsLink,
  // ApolloLink.from([uploadLink, errorLink, httpLink, authMiddleware])
  ApolloLink.from([errorLink, authMiddleware, uploadLink, httpLink]).concat(
    uploadLink
  )
);

export const client = new ApolloClient({
  cache,
  link
});

const rootPersistConfig = {
  storage: reduxStorage,
  key: "root",
  timeout: null,
  whitelist: ["usersReducer"]
};

const configurationPreferencesConfig = {
  storage: reduxStorage,
  key: "configuration",
  whitelist: ["usersReducer"]
};

// const settingsPreferencesConfig = {
//   storage: reduxStorage,
//   key: "settings",
//   whitelist: ["settingsReducer"]
// }

let rootReducer = combineReducers({
  usersReducer: persistReducer(configurationPreferencesConfig, usersReducer)
  // settingsReducer ,
});

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  persistReducer(rootPersistConfig, rootReducer),
  composeEnhancer(applyMiddleware(thunk))
);

// export const store = createStore(
//   usersReducer,
//   window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
// )

export const persistingStore = persistStore(store);

export default store;

const logoutUser = () => {
  // const isLoggedOut = localStorage.getItem("logout")
  // localStorage.removeItem("Visp.token");
  // apolloStorage.removeItem("apollo-cache-persist");
  persistingStore.purge();
  localStorage.clear();
  window.location.href = "/login";
};
