import React, { PureComponent } from "react";
import { AgGridReact } from "ag-grid-react";
import { columnDefs } from "../tableConfig";
import DatePickerRender from "./datePickerRender";
import { uniq, findIndex } from "lodash";
// import { Button } from 'reactstrap';

export default class Grid extends PureComponent {
  state = {
    gridRef: undefined,
    gridApi: undefined,
    rowData: [],
    modal: false,
    dataForAddBal: undefined
  };
  data = [];

  componentDidUpdate(prevProps) {
    if (prevProps.subscribeData !== this.props.subscribeData) {
      this.scramble();
    } else if (prevProps.licenseData !== this.props.licenseData) {
      const { gridApi } = this.state;
      if (gridApi) {
        gridApi.setRowData(this.props.licenseData);
      }
    }
  }

  scramble = () => {
    const { subscribeData } = this.props;
    if (subscribeData) {
      const { gridApi } = this.state;
      let columns = [];
      const item = subscribeData;
      let indx = findIndex(this.data, i => {
        return parseInt(i.id) === parseInt(item.id);
      });
      if (indx > -1) {
        const keys = Object.keys(item);
        let row = this.data[indx];
        keys.map(key => {
          if (item[key] !== row[key]) {
            row[key] = item[key];
            columns.push(key);
          }
          return true;
        });
        this.data[indx] = row;
      } else {
        item.id = parseInt(item.id);
        this.data.push(item);
      }
      console.log(this.data);
      gridApi.setRowData(this.data);
      columns = uniq(columns);
      const params = { force: false, columns };
      gridApi.refreshCells(params);
    }
  };
  onLoadGrid = params => {
    const { setGridApi, licenseData } = this.props;
    if (licenseData) {
      this.data = [...licenseData];
      params.api.setRowData(this.data);
      setGridApi(params.api);
      this.setState({ gridApi: params.api, gridRef: params });
    }
  };

  onCellValueChanged = async params => {
    let value = params.value;
    if (params.colDef.field === "mac_address") {
      const patt = new RegExp("^([0-9A-Fa-f]{2}[:-]){5}([0-9A-Fa-f]{2})$");
      console.log(" value ", value);
      if (patt.test(value) === false) {
        value = params.oldValue;
      }
    }
    if (params.value !== params.oldValue) {
      const { updateLicense, toastMessgage } = this.props;
      const data = { field: params.colDef.field, value, id: params.data.id };
      try {
        const response = await updateLicense({ variables: { data } });
        // refetch()
        toastMessgage("success", "Done");
        console.log(response);
      } catch (e) {
        toastMessgage("error", e.message);
      }
    }
  };

  onSelectionChanged = () => {
    const { selectionChanged } = this.props;
    const { gridApi } = this.state;
    const selectedRows = gridApi.getSelectedRows();
    let selection = false;
    if (selectedRows.length > 0) {
      selection = true;
    }
    selectionChanged(selection);
  };

  methodFromParent = data => {
    this.setState({ dataForAddBal: data, modal: true });
    // console.log("data", data)
  };

  setModal = val => {
    this.setState({ modal: val });
  };
  render() {
    const { rowData } = this.state;
    return (
      <>
        <div style={{ width: "100%", height: "100%" }}>
          <div
            id="myGrid"
            style={{
              height: "calc(100vh - 125px)",
              width: "100%"
            }}
            key="myGrid"
            className="ag-theme-balham"
          >
            <AgGridReact
              key={"AgGridReact"}
              onGridReady={this.onLoadGrid}
              enableRangeSelection={true}
              defaultColDef={{ resizable: true, editable: true }}
              columnDefs={columnDefs}
              rowData={rowData}
              rowSelection="multiple"
              // rowGroupPanelShow="always"
              animateRows={true}
              rowMultiSelectWithClick={false}
              floatingFilter={true}
              enterMovesDownAfterEdit={true}
              onCellValueChanged={this.onCellValueChanged}
              stopEditingWhenGridLosesFocus={true}
              onSelectionChanged={this.onSelectionChanged}
              enableCellChangeFlash={true}
              getRowNodeId={data => {
                return data.id;
              }}
              autoGroupColumnDef={{
                headerName: "Group",
                field: "customer_farm_name",
                width: 200,
                cellRenderer: "agGroupCellRenderer",
                cellRendererParams: { checkbox: true }
              }}
              groupSelectsChildren={true}
              context={{ componentParent: this }}
              frameworkComponents={{
                datePickerRender: DatePickerRender
              }}
            ></AgGridReact>
          </div>
        </div>
      </>
    );
  }
}
