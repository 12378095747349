import React, { PureComponent } from "react"
import { BrowserRouter, Switch, Route } from "react-router-dom"
import Login from "./pages/Login"
import AppContainer from "./containers/AppContainer"

class App extends PureComponent {
  render() {
    return (
      <BrowserRouter>
        <React.Suspense fallback={<div />}>
          <Switch>
            {/* <Route exact path="/" component={Login} /> */}
            <Route exact path="/login" component={Login} />
            <Route render={() => <AppContainer />} />  
          </Switch>
        </React.Suspense>
      </BrowserRouter>
    )
  }
}
export default App
