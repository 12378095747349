import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "reactstrap";
import "ag-grid-enterprise";
import { useQuery } from "@apollo/react-hooks";
import { useMutation } from "@apollo/react-hooks";
import { useSubscription } from "@apollo/react-hooks";
import {
  GETTRANSACTION,
  UPDATETRANSACTION,
  USERINFO_SUBSCRIBE
} from "./TransactionGraphQL";
import { useToasts } from "react-toast-notifications";
// import { remove } from "lodash";
// import DeleteBtn from "./components/deleteBtn"
import Grid from "./components/grid";
// import { faPlusCircle } from '@fortawesome/free-solid-svg-icons'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import AddTransaction from "./components/AddTransaction";

const Transactions = () => {
  const { addToast } = useToasts();
  let id = JSON.parse(localStorage.getItem("consolebot.userId"));
  const { data: subscribeData } = useSubscription(USERINFO_SUBSCRIBE, {
    variables: { userId: parseInt(id) }
  });

  // const { data: subscribeData } = useSubscription(USERINFO_SUBSCRIBE);
  const [updateTransaction] = useMutation(UPDATETRANSACTION);
  const { loading, data } = useQuery(GETTRANSACTION, {
    fetchPolicy: "network-only"
  });
  const [transData, settransData] = useState(undefined);
  // const [gridApi, setGridApi] = useState({});
  const [modal, setModal] = useState(false);

  const toastMessgage = (type, msg) => {
    addToast(msg, {
      appearance: type,
      autoDismiss: true
    });
  };
  // const updatetransData = () => {
  //   let tData
  //   if (data.getTransaction) {
  //     tData = transData
  //   } else {
  //     tData = data.getTransaction
  //   }
  //   settransData(tData)
  // }

  useEffect(() => {
    console.log("getTransaction ----------- ", data);
    if (data && data.getTransaction) {
      settransData(data.getTransaction);
      // updatetransData()
    }
    // eslint-disable-next-line
  }, [data]);

  // const deleteRecord = (idList) => {
  //   let tData = [...transData]
  //   remove(tData, item => {
  //     return idList.includes(item.id)
  //   })
  //   console.log("deleteRecord ----------- ", tData)
  //   settransData(tData)
  //   onSelectionChanged(false)
  // }

  const onSelectionChanged = () => {
    // setRowSelected(val);
  };

  const renderGrid = () => {
    if (loading) {
      return <div>Loading...</div>;
    } else {
      return (
        <Grid
          subscribeData={subscribeData && subscribeData.userInfo}
          toastMessgage={toastMessgage}
          selectionChanged={onSelectionChanged}
          transData={transData}
          updateTransaction={updateTransaction}
          // setGridApi={setGridApi}
        />
      );
    }
  };

  return (
    <main role="main" className="content-block__width ml-auto px-0">
      {/* Page detains stripe  */}
      <div className="page-details-stripe py-3">
        <Container fluid={true}>
          <Row className="justify-content-between align-items-center">
            <Col>
              <h1 className="stripe-heading m-0 font-weight-600">
                Transactions
              </h1>
            </Col>
            <Col xs="auto">
              {/* <DeleteBtn deleteRecord={deleteRecord} gridApi={gridApi} isRowSelected={isRowSelected} /> */}
            </Col>
            <Col xs="auto">
              {/* <FontAwesomeIcon icon={faPlusCircle} onClick={() => setModal(true)} className="lead text-primary" /> */}
            </Col>
          </Row>
        </Container>
      </div>
      <div id="hot-app" className="w-100 configuration-table">
        {renderGrid()}
        <AddTransaction setModal={setModal} modal={modal} />
      </div>
    </main>
  );
};
export default Transactions;
