import React, { Component } from 'react';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export default class AddBalance extends Component {
  constructor(props) {
    super(props);

    this.invokeParentMethod = this.invokeParentMethod.bind(this);
  }

  invokeParentMethod() {
    const { methodFromParent } = this.props.context.componentParent
    const { data } = this.props.node
    // console.log(node.data)
    methodFromParent(data);
  }

  render() {
    return (
      <div style={{ textAlign: "center" }}>
        <FontAwesomeIcon icon={faPlusCircle} onClick={this.invokeParentMethod} className="lead text-primary" />
      </div>
    );
  }
}