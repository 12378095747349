import React from "react"
import { render } from "react-dom"
import 'bootstrap/dist/css/bootstrap.min.css'
import './index.css'
import './styles/style.css'
import App from "./App"
import { ApolloProvider } from "react-apollo"
import { Provider } from "react-redux"
// // import { ApolloProvider } from "react-apollo" 
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import { PersistGate } from "redux-persist/integration/react"
import { ToastProvider } from 'react-toast-notifications'
import store, { persistingStore } from "./reducers/config";

import { client } from "./reducers/config"

const WebApp = () => (
    <ApolloProvider client={client}>
        <Provider store={store}>
            {/* <Router history={history}> */}

            <ToastProvider autoDismiss autoDismissTimeout={3000} >
                <PersistGate loading={null} persistor={persistingStore}>
                    <App />
                </PersistGate>
            </ToastProvider>

            {/* </Router> */}
        </Provider>
    </ApolloProvider >
)

render(<WebApp />, document.getElementById("root"))
