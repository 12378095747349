import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "reactstrap";
import "ag-grid-enterprise";
import { useQuery } from "@apollo/react-hooks";
import { useMutation } from "@apollo/react-hooks";
import { useSubscription } from "@apollo/react-hooks";
import {
  GETCUSTOMER,
  UPDATECUSTOMER,
  BALANCE_UPDATE_ADMIN
} from "./UserGraphQL";
import { useToasts } from "react-toast-notifications";
import { remove } from "lodash";
import DeleteBtn from "./components/deleteBtn";
import Grid from "./components/grid";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AddUser from "./components/AddUser";

const Users = () => {
  const { addToast } = useToasts();
  const { data: subscribeData } = useSubscription(BALANCE_UPDATE_ADMIN);
  // const { data: subscribeData } = useSubscription(USERINFO_SUBSCRIBE);
  const [updateCustomer] = useMutation(UPDATECUSTOMER);
  const { loading, data, refetch } = useQuery(GETCUSTOMER, {
    fetchPolicy: "network-only"
  });
  const [userData, setuserData] = useState(undefined);
  const [gridApi, setGridApi] = useState({});
  const [isRowSelected, setRowSelected] = useState(false);
  const [modal, setModal] = useState(false);

  const toastMessgage = (type, msg) => {
    addToast(msg, {
      appearance: type,
      autoDismiss: true
    });
  };
  // const updateuserData = () => {
  //   let uData;
  //   if (userData && userData.length) {
  //     uData = userData;
  //   } else {
  //     uData = data.getCustomer;
  //   }
  //   setuserData(uData);
  // };

  useEffect(() => {
    console.log("getCustomer ----------- ", data);
    if (data && data.getCustomer) {
      setuserData(data.getCustomer);
      // updateuserData()
    }
    // eslint-disable-next-line
  }, [data]);

  const deleteRecord = idList => {
    let uData = [...userData];
    remove(uData, item => {
      return idList.includes(item.id);
    });
    console.log("deleteRecord ----------- ", uData);
    setuserData(uData);
    onSelectionChanged(false);
  };

  const onSelectionChanged = val => {
    setRowSelected(val);
  };

  const renderGrid = () => {
    if (loading) {
      return <div>Loading...</div>;
    } else {
      return (
        <Grid
          subscribeData={subscribeData && subscribeData.balanceUpdateAdmin}
          toastMessgage={toastMessgage}
          selectionChanged={onSelectionChanged}
          userData={userData}
          updateCustomer={updateCustomer}
          setGridApi={setGridApi}
        />
      );
    }
  };

  return (
    <main role="main" className="content-block__width ml-auto px-0">
      {/* Page detains stripe  */}
      <div className="page-details-stripe py-3">
        <Container fluid={true}>
          <Row className="justify-content-between align-items-center">
            <Col>
              <h1 className="stripe-heading m-0 font-weight-600">Users</h1>
            </Col>
            <Col xs="auto">
              <DeleteBtn
                deleteRecord={deleteRecord}
                gridApi={gridApi}
                isRowSelected={isRowSelected}
              />
            </Col>
            <Col xs="auto">
              <FontAwesomeIcon
                icon={faPlusCircle}
                onClick={() => setModal(true)}
                className="lead text-primary"
              />
            </Col>
          </Row>
        </Container>
      </div>
      <div id="hot-app" className="w-100 configuration-table">
        {renderGrid()}
        <AddUser refetch={refetch} setModal={setModal} modal={modal} />
      </div>
    </main>
  );
};
export default Users;
