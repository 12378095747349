import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "reactstrap";
import "ag-grid-enterprise";
import { useQuery } from "@apollo/react-hooks";
import { useMutation } from "@apollo/react-hooks";
// import { useSubscription } from '@apollo/react-hooks';
import { GETLICENSE, UPDATELICENSE } from "./LicenseGraphQL";
import { useToasts } from "react-toast-notifications";
import { remove } from "lodash";
import Grid from "./components/grid";
// import { compose } from "redux";
import DeleteBtn from "./components/deleteBtn";

const Licensing = () => {
  const { addToast } = useToasts();
  const { loading, data } = useQuery(GETLICENSE, {
    fetchPolicy: "network-only"
  });
  const [updateLicense] = useMutation(UPDATELICENSE);
  const [licenseData, setlicenseData] = useState(undefined);
  const [gridApi, setGridApi] = useState({});
  const [isRowSelected, setRowSelected] = useState(false);
  // const [modal, setModal] = useState(false);

  const toastMessgage = (type, msg) => {
    addToast(msg, {
      appearance: type,
      autoDismiss: true
    });
  };
  const updatelicenseData = () => {
    let lData;
    if (licenseData && licenseData.length) {
      lData = licenseData;
    } else {
      lData = data.getLicense;
    }
    setlicenseData(lData);
  };

  useEffect(() => {
    console.log("getLicense ----------- ", data);
    if (data && data.getLicense) {
      updatelicenseData();
    }
    // eslint-disable-next-line
  }, [data]);

  const deleteRecord = idList => {
    let lData = [...licenseData];
    remove(lData, item => {
      return idList.includes(item.id);
    });
    console.log("deleteRecord ----------- ", lData);
    setlicenseData(lData);
    onSelectionChanged(false);
  };

  const onSelectionChanged = val => {
    setRowSelected(val);
  };

  const renderGrid = () => {
    if (loading) {
      return <div>Loading...</div>;
    } else {
      console.log(" getLicense ***********--------- ", licenseData);
      return (
        <Grid
          toastMessgage={toastMessgage}
          selectionChanged={onSelectionChanged}
          licenseData={licenseData}
          updateLicense={updateLicense}
          setGridApi={setGridApi}
        />
      );
    }
  };

  return (
    <main role="main" className="content-block__width ml-auto px-0">
      {/* Page detains stripe  */}
      <div className="page-details-stripe py-3">
        <Container fluid={true}>
          <Row className="justify-content-between align-items-center">
            <Col>
              <h1 className="stripe-heading m-0 font-weight-600">Licensing</h1>
            </Col>
            <Col xs="auto">
              <DeleteBtn
                deleteRecord={deleteRecord}
                gridApi={gridApi}
                isRowSelected={isRowSelected}
              />
            </Col>
          </Row>
        </Container>
      </div>
      <div id="hot-app" className="w-100 configuration-table">
        {renderGrid()}
      </div>
    </main>
  );
};
export default Licensing;
