import React, { useState } from "react";
import { Col, Row, Button, Form, Label, Input, FormGroup } from "reactstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import generator from "generate-password";
import { useToasts } from "react-toast-notifications";
import { useMutation } from "@apollo/react-hooks";
import { ADDCUSTOMER, ISEMAILEXSIT } from "../../../UserGraphQL";
import { IoIosEye, IoIosEyeOff } from "react-icons/io";
import { userGridIntegerFields } from "../../grid";

const initialValues = {
  name: "",
  email: "",
  password: generator.generate({ length: 40, numbers: true }),
  is_super_admin: false,
  balance: 0,
  skype: "",
  telegram: "",
  notes: "",
  ea_reg: 0,
  psn_reg: 0,
  psn_signin: 0
};

const checkZeros = obj => {
  const newObj = { ...obj };
  Object.keys(obj).forEach(key => {
    if (userGridIntegerFields[key] && !obj[key] && newObj[key] !== 0) {
      newObj[key] = 0;
    }
  });
  return newObj;
};

const validation = Yup.object().shape({
  name: Yup.string().required("Required"),
  email: Yup.string().email().required("Required"),
  password: Yup.string().required("Required")
  // confirm_password: Yup.string().required().oneOf([yup.ref('password'), null],'Passwords must match'),
});

const AddUserFrom = props => {
  const { addToast } = useToasts();
  const { setModal, refetch } = props;
  const [hidden, setHidden] = useState(true);
  const [addCustomer] = useMutation(ADDCUSTOMER);
  const [isEmailExsit] = useMutation(ISEMAILEXSIT);

  const toastMessgage = (type, msg) => {
    addToast(msg, {
      appearance: type,
      autoDismiss: true
    });
  };

  const submitForm = async values => {
    const response = await isEmailExsit({ variables: { email: values.email } });
    console.log(response.data.isEmailExsit);
    if (response.data.isEmailExsit === null) {
      try {
        const response = await addCustomer({
          variables: { customerDetails: checkZeros(values) }
        });
        refetch();
        toastMessgage("success", "Done");
        console.log(response);
        setModal(false);
      } catch (e) {
        toastMessgage("error", e.message);
      }
    } else {
      toastMessgage("error", "Email Already Exists!");
    }
  };

  const showHidePass = val => {
    setHidden(val);
  };
  initialValues.password = generator.generate({ length: 40, numbers: true });
  return (
    <div>
      <Formik
        initialValues={initialValues}
        onSubmit={async values => {
          submitForm(values);
        }}
        validationSchema={validation}
      >
        {props => {
          const {
            values,
            touched,
            errors,
            handleChange,
            handleBlur,
            handleSubmit,
            isValid
          } = props;
          return (
            <Form onSubmit={handleSubmit}>
              <FormGroup>
                <Label for="name">Name</Label>
                <Input
                  id="name"
                  placeholder="Enter name"
                  type="text"
                  value={values.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={
                    errors.name && touched.name
                      ? "text-input error"
                      : "text-input"
                  }
                />
                {errors.name && touched.name && (
                  <div className="input-feedback">{errors.name}</div>
                )}
              </FormGroup>

              <Row form>
                <Col md={6}>
                  <FormGroup>
                    <Label for="email">Email</Label>
                    <Input
                      id="email"
                      placeholder="Enter email"
                      type="email"
                      value={values.email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className={
                        errors.email && touched.email
                          ? "text-input error"
                          : "text-input"
                      }
                    />
                    {errors.email && touched.email && (
                      <div className="input-feedback">{errors.email}</div>
                    )}
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <Label for="password">Password</Label>
                    <div className="password-key">
                      <Input
                        autoComplete="new-password"
                        id="password"
                        placeholder="Enter password"
                        type={hidden ? "password" : "text"}
                        value={values.password}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className={
                          errors.password && touched.password
                            ? "text-input error"
                            : "text-input"
                        }
                      />
                      {errors.password && touched.password && (
                        <div className="input-feedback">{errors.password}</div>
                      )}
                      {hidden ? (
                        <IoIosEye
                          size={"1.5em"}
                          onClick={() => showHidePass(false)}
                        />
                      ) : (
                        <IoIosEyeOff
                          size={"1.5em"}
                          onClick={() => showHidePass(true)}
                        />
                      )}
                    </div>
                  </FormGroup>
                </Col>
              </Row>
              <Row form>
                <Col md={3} className="d-flex align-items-end">
                  <FormGroup>
                    <div className="custom-control custom-switch pb-3">
                      <Input
                        type="checkbox"
                        className="custom-control-input"
                        id="is_super_admin"
                        checked={values.is_super_admin}
                        onChange={handleChange}
                        readOnly
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="is_super_admin"
                      >
                        Is Admin
                      </label>
                    </div>
                  </FormGroup>
                </Col>
                <Col md={3}>
                  <FormGroup>
                    <Label for="ea_reg">EA Reg</Label>
                    <Input
                      id="ea_reg"
                      placeholder="Enter EA reg"
                      type="number"
                      value={values.ea_reg}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </FormGroup>
                </Col>
                <Col md={3}>
                  <FormGroup>
                    <Label for="psn_reg">PSN reg</Label>
                    <Input
                      id="psn_reg"
                      placeholder="Enter PSN reg"
                      type="number"
                      value={values.psn_reg}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </FormGroup>
                </Col>
                <Col md={3}>
                  <FormGroup>
                    <Label for="psn_signin">Sign-in</Label>
                    <Input
                      id="psn_signin"
                      placeholder="Enter Sign-in"
                      type="number"
                      value={values.psn_signin}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </FormGroup>
                </Col>
              </Row>

              <FormGroup>
                <Label for="balance">Balance</Label>
                <Input
                  id="balance"
                  placeholder="Enter balance"
                  type="number"
                  value={values.balance}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </FormGroup>
              <Row form>
                <Col md={6}>
                  <FormGroup>
                    <Label for="skype">Skype</Label>
                    <Input
                      id="skype"
                      placeholder="Enter skype"
                      type="text"
                      value={values.skype}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <Label for="telegram">Telegram</Label>
                    <Input
                      id="telegram"
                      placeholder="Enter telegram"
                      type="text"
                      value={values.telegram}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <FormGroup>
                <Label for="notes">Notes</Label>
                <Input
                  id="notes"
                  placeholder="Enter notes"
                  type="text"
                  value={values.notes}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </FormGroup>

              <Row form>
                <Col md={6}></Col>
                <Col md={6} style={{ textAlign: "right" }}>
                  <Button
                    color="primary"
                    size="sm"
                    type="submit"
                    disabled={!isValid}
                  >
                    Submit
                  </Button>{" "}
                  <Button
                    color="primary"
                    size="sm"
                    type="buttom"
                    onClick={() => setModal(false)}
                  >
                    Cancel
                  </Button>
                </Col>
              </Row>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default AddUserFrom;
