import React, { PureComponent } from "react";
import { AgGridReact } from "ag-grid-react";
import { columnDefs } from "../tableConfig";
import CheckBoxRender from "./checkBoxRender";
import { uniq, findIndex } from "lodash";
import AddBalanceForm from "./AddBalanceForm";
import AddBalance from "./addBalance";
// import { Button } from 'reactstrap';

export const userGridIntegerFields = {
  ea_reg: true,
  psn_reg: true,
  psn_signin: true
};

export default class Grid extends PureComponent {
  state = {
    gridRef: undefined,
    gridApi: undefined,
    rowData: [],
    modal: false,
    dataForAddBal: undefined
  };
  data = [];

  componentDidUpdate(prevProps) {
    if (prevProps.subscribeData !== this.props.subscribeData) {
      this.scramble();
    } else if (prevProps.userData !== this.props.userData) {
      const { gridApi } = this.state;
      if (gridApi) {
        gridApi.setRowData(this.props.userData);
      }
    }
  }

  scramble = () => {
    const { subscribeData } = this.props;
    if (subscribeData) {
      const { gridApi } = this.state;
      let columns = [];
      const item = subscribeData;
      let indx = findIndex(this.data, i => {
        return parseInt(i.id) === parseInt(item.id);
      });
      if (indx > -1) {
        const keys = Object.keys(item);
        let row = this.data[indx];
        keys.map(key => {
          if (item[key] !== row[key]) {
            row[key] = item[key];
            columns.push(key);
          }
          return true;
        });
        this.data[indx] = row;
      } else {
        item.id = parseInt(item.id);
        this.data.push(item);
      }
      console.log(this.data);
      gridApi.setRowData(this.data);
      columns = uniq(columns);
      const params = { force: false, columns };
      gridApi.refreshCells(params);
    }
  };

  onLoadGrid = params => {
    const { setGridApi, userData } = this.props;
    if (userData) {
      this.data = [...userData];
      params.api.setRowData(this.data);
      setGridApi(params.api);
      this.setState({ gridApi: params.api, gridRef: params });
    }
  };

  onCellValueChanged = async params => {
    console.log("newValue --------- ", params.colDef.field);
    if (params.value !== params.oldValue) {
      const { updateCustomer, toastMessgage } = this.props;
      let value = params.value;
      if (params.colDef.field === "balance") {
        value =
          params.value === null ||
          params.value === undefined ||
          params.value === ""
            ? 0
            : parseFloat(params.value);
      } else if (userGridIntegerFields[params.colDef.field]) {
        value =
          params.value && parseInt(params.value) ? parseInt(params.value) : 0;
      }
      const data = {
        field: params.colDef.field,
        value: value,
        id: params.data.id
      };
      try {
        const response = await updateCustomer({ variables: { data } });
        // refetch()
        toastMessgage("success", "Done");
        console.log(response);
      } catch (e) {
        toastMessgage("error", e.message);
      }
    }
  };

  onSelectionChanged = () => {
    const { selectionChanged } = this.props;
    const { gridApi } = this.state;
    const selectedRows = gridApi.getSelectedRows();
    let selection = false;
    if (selectedRows.length > 0) {
      selection = true;
    }
    selectionChanged(selection);
  };

  methodFromParent = data => {
    this.setState({ dataForAddBal: data, modal: true });
    console.log("data", data);
  };

  setModal = val => {
    this.setState({ modal: val });
  };
  render() {
    const { rowData, modal, dataForAddBal } = this.state;
    return (
      <>
        <div style={{ width: "100%", height: "100%" }}>
          <div
            id="myGrid"
            style={{
              height: "calc(100vh - 125px)",
              width: "100%"
            }}
            key="myGrid"
            className="ag-theme-balham"
          >
            <AgGridReact
              key={"AgGridReact"}
              onGridReady={this.onLoadGrid}
              enableRangeSelection={true}
              defaultColDef={{ resizable: true, editable: true }}
              columnDefs={columnDefs}
              rowData={rowData}
              rowSelection="multiple"
              rowGroupPanelShow="always"
              animateRows={true}
              rowMultiSelectWithClick={false}
              floatingFilter={true}
              enterMovesDownAfterEdit={true}
              onCellValueChanged={this.onCellValueChanged}
              stopEditingWhenGridLosesFocus={true}
              onSelectionChanged={this.onSelectionChanged}
              enableCellChangeFlash={true}
              getRowNodeId={data => {
                return data.id;
              }}
              autoGroupColumnDef={{
                headerName: "Group",
                field: "customer_farm_name",
                width: 200,
                cellRenderer: "agGroupCellRenderer",
                cellRendererParams: { checkbox: true }
              }}
              groupSelectsChildren={true}
              context={{ componentParent: this }}
              frameworkComponents={{
                checkBoxRender: CheckBoxRender,
                addBalance: AddBalance
              }}
            ></AgGridReact>
          </div>
        </div>
        <AddBalanceForm
          modal={modal}
          setModal={this.setModal}
          dataForAddBal={dataForAddBal}
        />
      </>
    );
  }
}
