import React from "react";
import { Container, Row, Col } from "reactstrap";
import "ag-grid-enterprise";
import { useQuery } from "@apollo/react-hooks";
import { useMutation } from "@apollo/react-hooks";
import { GETADMINSETTING, UPDATEADMINSETTING } from "./SettingsGraphQL";
import { useToasts } from "react-toast-notifications";
// import { remove } from "lodash";
import MonthlyLicensePrice from "./components/MonthlyLicensePrice";

const Settings = () => {
  const { addToast } = useToasts();
  const { data } = useQuery(GETADMINSETTING, { fetchPolicy: "network-only" });
  const [updateAdminSetting] = useMutation(UPDATEADMINSETTING);
  // const [adminSettingData, setAdminSettingData] = useState(undefined);
  let setting = undefined;
  if (data) {
    setting = data.getAdminSetting[0];
  }

  const toastMessgage = (type, msg) => {
    addToast(msg, {
      appearance: type,
      autoDismiss: true
    });
  };

  return (
    <main role="main" className="content-block__width ml-auto px-0">
      {/* Page detains stripe  */}
      <div className="page-details-stripe py-3">
        <Container fluid={true}>
          <Row className="justify-content-between align-items-center">
            <Col>
              <h1 className="stripe-heading m-0 font-weight-600">Settings</h1>
            </Col>
          </Row>
        </Container>
      </div>
      <div id="hot-app" className="w-100 configuration-table">
        <MonthlyLicensePrice
          toastMessgage={toastMessgage}
          setting={setting}
          updateAdminSetting={updateAdminSetting}
        />
      </div>
    </main>
  );
};
export default Settings;
