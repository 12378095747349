import React, { PureComponent } from "react";
import { AgGridReact } from "ag-grid-react";
import { columnDefs } from "../tableConfig";
import { uniq, findIndex } from "lodash";
// import { Button } from 'reactstrap';

export default class Grid extends PureComponent {
  state = { gridRef: undefined, gridApi: undefined, rowData: [] };
  data = [];

  componentDidUpdate(prevProps) {
    if (prevProps.subscribeData !== this.props.subscribeData) {
      this.scramble();
    } else if (prevProps.transData !== this.props.transData) {
      const { gridApi } = this.state;
      if (gridApi) {
        gridApi.setRowData(this.props.transData);
      }
    }
  }

  scramble = () => {
    const { subscribeData } = this.props;
    if (subscribeData) {
      const { gridApi } = this.state;
      let columns = [];
      const item = subscribeData;
      let indx = findIndex(this.data, i => {
        return parseInt(i.id) === parseInt(item.id);
      });
      if (indx > -1) {
        const keys = Object.keys(item);
        let row = this.data[indx];
        keys.map(key => {
          if (parseInt(item[key]) !== parseInt(row[key])) {
            row[key] = item[key];
            columns.push(key);
          }
          return true;
        });
        this.data[indx] = row;
      } else {
        item.id = parseInt(item.id);
        this.data.push(item);
      }
      console.log(this.data);
      gridApi.setRowData(this.data);
      columns = uniq(columns);
      const params = { force: false, columns };
      gridApi.refreshCells(params);
    }
  };
  onLoadGrid = params => {
    const { transData } = this.props;
    if (transData) {
      this.data = [...transData];
      params.api.setRowData(this.data);
      // setGridApi(params.api);
      this.setState({ gridApi: params.api, gridRef: params });
    }
  };

  onCellValueChanged = async params => {
    console.log("newValue --------- ", params.colDef.field);
    if (params.value !== params.oldValue) {
      const { updateTransaction, toastMessgage } = this.props;
      const data = {
        field: params.colDef.field,
        value: params.value,
        id: params.data.id
      };
      try {
        const response = await updateTransaction({ variables: { data } });
        // refetch()
        toastMessgage("success", "Done");
        console.log(response);
      } catch (e) {
        toastMessgage("error", e.message);
      }
    }
  };

  onSelectionChanged = () => {
    const { selectionChanged } = this.props;
    const { gridApi } = this.state;
    const selectedRows = gridApi.getSelectedRows();
    let selection = false;
    if (selectedRows.length > 0) {
      selection = true;
    }
    selectionChanged(selection);
  };
  methodFromParent = data => {
    console.log("data", data);
  };
  render() {
    const { rowData } = this.state;
    return (
      <>
        <div style={{ width: "100%", height: "100%" }}>
          <div
            id="myGrid"
            style={{
              height: "calc(100vh - 125px)",
              width: "100%"
            }}
            key="myGrid"
            className="ag-theme-balham"
          >
            <AgGridReact
              key={"AgGridReact"}
              onGridReady={this.onLoadGrid}
              enableRangeSelection={true}
              defaultColDef={{ resizable: true }}
              columnDefs={columnDefs}
              rowData={rowData}
              rowSelection="multiple"
              rowGroupPanelShow="always"
              animateRows={true}
              rowMultiSelectWithClick={true}
              floatingFilter={true}
              onSelectionChanged={this.onSelectionChanged}
              enableCellChangeFlash={true}
              getRowNodeId={data => {
                return data.id;
              }}
              autoGroupColumnDef={{
                headerName: "Date",
                field: "t_date",
                width: 200,
                cellRenderer: "agGroupCellRenderer",
                cellRendererParams: { checkbox: true }
              }}
              groupSelectsChildren={true}
              // rowClassRules={{
              //   'rag-green': (params) => { return params.data.is_pair },
              //   'rag-red': (params) => { return params.data.is_pair === false },
              // }}
            ></AgGridReact>
          </div>
        </div>
      </>
    );
  }
}
