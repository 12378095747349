// import CheckBoxRender from "../components/checkBoxRender"
export const columnDefs = [
  // { headerName: "Id", field: "id", sortable: true, filter: "agTextColumnFilter", width: 90, editable: false },
  {
    headerName: "Name",
    field: "name",
    sortable: true,
    filter: "agTextColumnFilter",
    width: 90
  },
  {
    headerName: "Email",
    field: "email",
    sortable: true,
    filter: "agTextColumnFilter"
  },
  {
    headerName: "Password",
    field: "password",
    sortable: true,
    filter: "agTextColumnFilter"
  },
  {
    headerName: "Super Admin",
    field: "is_super_admin",
    sortable: true,
    filter: "agTextColumnFilter",
    width: 90
    // cellRenderer:  "checkBoxRender"
  },
  {
    headerName: "Balance",
    field: "balance",
    sortable: true,
    filter: "agTextColumnFilter",
    width: 90
  },
  {
    headerName: "Skype",
    field: "skype",
    sortable: true,
    filter: "agTextColumnFilter",
    width: 90
  },
  {
    headerName: "Telegram",
    field: "telegram",
    sortable: true,
    filter: "agTextColumnFilter",
    width: 90
  },
  {
    headerName: "EA Reg",
    field: "ea_reg",
    sortable: true,
    filter: "agTextColumnFilter",
    width: 90
  },
  {
    headerName: "PSN Reg",
    field: "psn_reg",
    sortable: true,
    filter: "agTextColumnFilter",
    width: 90
  },
  {
    headerName: "Sign-in",
    field: "psn_signin",
    sortable: true,
    filter: "agTextColumnFilter",
    width: 90
  },
  {
    headerName: "Notes",
    field: "notes",
    sortable: true,
    filter: "agTextColumnFilter"
  },
  {
    headerName: "Add Balance",
    field: "#",
    sortable: true,
    filter: "agTextColumnFilter",
    width: 90,
    cellRenderer: "addBalance"
  }
];
