import React, { useState } from "react"
import { Row, Col, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { DELETELICENSE } from "../LicenseGraphQL"
import { useMutation } from '@apollo/react-hooks';
import { useToasts } from 'react-toast-notifications'

export const DeleteBtn = (props) => {
    const { className, isRowSelected, deleteRecord } = props;
    const [modal, toggle] = useState(false);
    const [deleteLicense] = useMutation(DELETELICENSE);
    const { addToast } = useToasts()

    const buttonClick = (e) => {
        toggle(true)
    };

    const deleteRow = async () => {
        try {
            const selectedNodes = props.gridApi.getSelectedNodes()
            let idList = []

            selectedNodes.map(node => {
                idList.push(parseInt(node.data.id))
                // props.gridApi.updateRowData({ remove: [node.data] })  // It will update the row 
                return node
            })
            await deleteLicense({ variables: { id: idList } });
            deleteRecord(idList)
            // let deletedRow = props.node.data;
            // props.gridApi.updateRowData({ remove: [selectedData] })  // It will update the row
            toggle(false)
            addToast("Done", {
                appearance: 'success',
                autoDismiss: true,
            })
        }
        catch (e) {
            addToast(e.message, {
                appearance: 'error',
                autoDismiss: true,
            })
        }
    }
    return (
        <>
            <Button size="sm" color="primary" disabled={!isRowSelected} onClick={buttonClick}>Delete</Button>{' '}

            <Modal isOpen={modal} centered={true} zIndex={999999999} className={className} tabIndex="4" role="dialog">
                <ModalHeader tag="h6" className="py-2 text-dark bg-light shadow-sm">Delete Row</ModalHeader>
                <ModalBody>
                    Do you want to delete this record ?
                </ModalBody>
                <ModalFooter className="d-block">
                    <Row className="justify-content-center">
                        <Col xs="7" className="pr-0">
                            <Button color="primary" block onClick={deleteRow} >Yes</Button>
                        </Col>
                        <Col>
                            <Button color="secondary" block onClick={() => toggle(false)}>Cancel</Button>
                        </Col>
                    </Row>
                </ModalFooter>
            </Modal>
        </>
    )
}
export default DeleteBtn