import React from "react";
import { Col, Row, Button, Form, Label, Input, FormGroup } from "reactstrap";
import { Formik } from "formik";
import * as Yup from "yup";
// import generator from "generate-password"
import { useToasts } from "react-toast-notifications";
import { useMutation } from "@apollo/react-hooks";
import { ADDTRANSACTION } from "../../../TransactionGraphQL";
// import { IoIosEye, IoIosEyeOff } from "react-icons/io";

const initialValues = {
  t_date: "",
  type: "",
  issuer: "",
  useremail: "",
  size: "",
  starting_balance: "",
  balance_after: ""
};

const validation = Yup.object().shape({
  t_date: Yup.string().required("Required"),
  type: Yup.string().required("Required"),
  issuer: Yup.string().required("Required"),
  useremail: Yup.string().required("Required"),
  size: Yup.string().required("Required"),
  starting_balance: Yup.string().required("Required"),
  balance_after: Yup.string().required("Required")
  // confirm_password: Yup.string().required().oneOf([yup.ref('password'), null],'Passwords must match'),
});

const AddTransactionFrom = props => {
  const { addToast } = useToasts();
  const { setModal } = props;
  // const [hidden, setHidden] = useState(true)
  const [addTransaction] = useMutation(ADDTRANSACTION);

  const toastMessgage = (type, msg) => {
    addToast(msg, {
      appearance: type,
      autoDismiss: true
    });
  };

  const submitForm = async values => {
    // alert(JSON.stringify(values))
    // return false
    try {
      const response = await addTransaction({
        variables: { customerDetails: { ...values } }
      });
      toastMessgage("success", "Done");
      console.log(response);
      setModal(false);
    } catch (e) {
      toastMessgage("error", e.message);
    }
  };

  return (
    <div>
      <Formik
        initialValues={initialValues}
        onSubmit={async values => {
          submitForm(values);
        }}
        validationSchema={validation}
      >
        {props => {
          const {
            values,
            touched,
            errors,
            handleChange,
            handleBlur,
            handleSubmit,
            isValid
          } = props;
          return (
            <Form onSubmit={handleSubmit}>
              <FormGroup>
                <Label for="t_date">Date</Label>
                <Input
                  id="t_date"
                  placeholder="Enter date"
                  type="date"
                  value={values.t_date}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={
                    errors.t_date && touched.t_date
                      ? "text-input error"
                      : "text-input"
                  }
                />
                {errors.t_date && touched.t_date && (
                  <div className="input-feedback">{errors.t_date}</div>
                )}
              </FormGroup>

              <Row form>
                <Col md={6}>
                  <FormGroup>
                    <Label for="type">Type</Label>
                    <Input
                      id="type"
                      placeholder="Enter type"
                      type="text"
                      value={values.type}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className={
                        errors.type && touched.type
                          ? "text-input error"
                          : "text-input"
                      }
                    />
                    {errors.type && touched.type && (
                      <div className="input-feedback">{errors.type}</div>
                    )}
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <Label for="issuer">Issuer</Label>
                    <Input
                      id="issuer"
                      placeholder="Enter issuer"
                      type="text"
                      value={values.issuer}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className={
                        errors.issuer && touched.issuer
                          ? "text-input error"
                          : "text-input"
                      }
                    />
                    {errors.issuer && touched.issuer && (
                      <div className="input-feedback">{errors.issuer}</div>
                    )}
                  </FormGroup>
                </Col>
              </Row>

              <FormGroup>
                <Label for="useremail">User Email</Label>
                <Input
                  id="useremail"
                  placeholder="Enter useremail"
                  type="text"
                  value={values.useremail}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={
                    errors.useremail && touched.useremail
                      ? "text-input error"
                      : "text-input"
                  }
                />
                {errors.useremail && touched.useremail && (
                  <div className="input-feedback">{errors.useremail}</div>
                )}
              </FormGroup>

              <FormGroup>
                <Label for="size">Size</Label>
                <Input
                  id="size"
                  placeholder="Enter size"
                  type="text"
                  value={values.size}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={
                    errors.size && touched.size
                      ? "text-input error"
                      : "text-input"
                  }
                />
                {errors.size && touched.size && (
                  <div className="input-feedback">{errors.size}</div>
                )}
              </FormGroup>
              <Row form>
                <Col md={6}>
                  <FormGroup>
                    <Label for="starting_balance">Starting Balance</Label>
                    <Input
                      id="starting_balance"
                      placeholder="Enter start balance"
                      type="number"
                      value={values.starting_balance}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className={
                        errors.starting_balance && touched.starting_balance
                          ? "text-input error"
                          : "text-input"
                      }
                    />
                    {errors.starting_balance && touched.starting_balance && (
                      <div className="input-feedback">
                        {errors.starting_balance}
                      </div>
                    )}
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <Label for="balance_after">Balance after</Label>
                    <Input
                      id="balance_after"
                      placeholder="Enter balance after"
                      type="number"
                      value={values.balance_after}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className={
                        errors.balance_after && touched.balance_after
                          ? "text-input error"
                          : "text-input"
                      }
                    />
                    {errors.balance_after && touched.balance_after && (
                      <div className="input-feedback">
                        {errors.balance_after}
                      </div>
                    )}
                  </FormGroup>
                </Col>
              </Row>

              <Row form>
                <Col md={6}></Col>
                <Col md={6} style={{ textAlign: "right" }}>
                  <Button
                    color="primary"
                    size="sm"
                    type="submit"
                    disabled={!isValid}
                  >
                    Submit
                  </Button>{" "}
                  <Button
                    color="primary"
                    size="sm"
                    type="buttom"
                    onClick={() => setModal(false)}
                  >
                    Cancel
                  </Button>
                </Col>
              </Row>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default AddTransactionFrom;
