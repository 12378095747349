import { gql } from "apollo-boost"

export const UPDATETRANSACTION = gql`
mutation updateTransaction($data : JSON!){
  updateTransaction(input :$data )
}
`
export const DELETETRANSACTION = gql`
mutation deleteTransaction($id : [Int]!){
  deleteTransaction(id :$id )
}
`
export const ADDTRANSACTION = gql`
mutation addTransaction($transDetails : JSON!){
  addTransaction(input :$transDetails ){
    id 
    t_date 
    type 
    issuer 
    useremail 
    amount 
    starting_balance 
    balance_after ,
    note
  }
}
`
export const GETTRANSACTION = gql`
query getTransaction
{ 
  getTransaction{
    id 
    t_date 
    type 
    issuer 
    useremail 
    amount 
    starting_balance 
    balance_after
    note 
  }
}
` 

export const USERINFO_SUBSCRIBE = gql`
subscription userInfo($userId : Int!){
  userInfo(userId : $userId)
}
` 
