import localforage from "localforage"

// Issue where iphone's use webSQL rather than indexedDB which triggers some cache issues...
export const reduxStorage = localforage.createInstance({
    driver: [localforage.WEBSQL,
             localforage.INDEXEDDB,
             localforage.LOCALSTORAGE],
    name: "console-Bot"
  }); 

export default reduxStorage