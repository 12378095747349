import {gql} from "apollo-boost"

export const ADMINLOGIN = gql`
mutation adminLogin($email : String!, $password : String!){
  adminLogin(email :$email, password : $password){
      token
      isNew
      user
    }
  }
`
 